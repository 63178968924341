import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import {Helmet} from "react-helmet";
import "../assets/css/style.css";
import Navbar from "../components/navbar";
import Thanks from "../components/thanks";
// import Contact from "../components/contact";
// import Footer from "../components/footer";

// styles
const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
// markup
const ThanksPage = () => {

  React.useEffect(()=>{
    // Event Snippit Here
    
    <script defer type="application/javascript" src="https://tracker.clickguard.com/sc"></script>
  },[])

  const data = useStaticQuery(graphql`
        query ThanksPage {
            wpProject {
                projectMeta {
                  projectId
                  project
                }
            }

            wpTitle {
              titleMeta {
                title
                favicon {
                  sourceUrl
                }
              }
            }
            
            wpThemeColor {
              themeColorMeta {
                primary
                secondary
                background
              }
            }
        }
    `)

    const projectid = data.wpProject.projectMeta.projectId
    const project = data.wpProject.projectMeta.project
    const title = data.wpTitle.titleMeta.title
    const favicon = data.wpTitle.titleMeta.favicon.sourceUrl
    const background = data.wpThemeColor.themeColorMeta.background
    const primarycolor = data.wpThemeColor.themeColorMeta.primary
    const secondarycolor = data.wpThemeColor.themeColorMeta.secondary

    React.useEffect(()=>{
      document.documentElement.style.setProperty('--bg-color', background);
      document.documentElement.style.setProperty('--primary', primarycolor);
      document.documentElement.style.setProperty('--secondary', secondarycolor);
    },[])

  return (
    <main style={pageStyles}>
      <Helmet>
        <title>{title}</title>
        <script src='https://ginfomedia.com/mlschatbot/chatbot.js'></script>
        <link rel="apple-touch-icon" type="image" href={favicon} />
        <link rel="icon" type="image" href={favicon} />
        <script type="text/javascript">
            {/* {`gtag('event', 'conversion', {'send_to': 'AW-834441425/k6VDCNqO1I8YENGh8o0D'});`} */}
        </script>
        
      </Helmet>
      <Navbar />
      <Thanks />
      {/* <Contact />
      <Footer /> */}
    </main>
  )
}

export default ThanksPage
