
import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import "./style.css";

const fnRedirect = (link) => {
    if (typeof window !== 'undefined') {
        window.location.href = link;
    }
}

const Thanks = () => {

    const data = useStaticQuery(graphql`
    query Thanks {
        wpContact {
            contactMeta {
              primaryNumber
              secondaryNumber
            }
        }
        wpThemeColor {
            themeColorMeta {
              primary
              secondary
              background
            }
        }
    }
`)

    const primarycolor = data.wpThemeColor.themeColorMeta.primary
    const secondarycolor = data.wpThemeColor.themeColorMeta.secondary
    const date = new Date();
    const time = date.getHours();
    let contact_number;
    if(time < 19){
        contact_number = data.wpContact.contactMeta.primaryNumber
    }else{
        contact_number = data.wpContact.contactMeta.secondaryNumber
    }

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0} sx={{ px: { xs: 2, md: 10, lg: 30 }, py: { xs: 12, lg: 15 } }}>
                    <Grid item xs={12}>
                        <Typography variant="h4" sx={{ textAlign: 'center', textTransform: 'uppercase', mb: 1 }}>
                            Thank you
                        </Typography>
                        <hr />

                        <Typography variant="h6" sx={{ textAlign: 'center', textTransform: 'uppercase', mb: 1 }}>
                        Thank You for your interest in the project. Our sales executive will get in touch with you shortly.
To Get Instant Information Please Call.
                        </Typography>


                        <Typography variant="h6" sx={{ textAlign: 'center', mb: 3 }}>
                            +{contact_number}
                        </Typography>

                        <Box sx={{ textAlign: 'center' }}>
                            <Button sx={{ backgroundColor: primarycolor, "&:hover": { backgroundColor: secondarycolor } }} onClick={() => fnRedirect("/")} variant="contained">
                                Back To Home
                            </Button>
                        </Box>


                    </Grid>
                </Grid>
            </Box>

        </>
    );
};

export default Thanks;